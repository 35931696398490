import React from "react";

interface ISectionHeaderProps {
  heading: string;
  subheading: string;
  className?: string;
}
export default ({ heading, subheading, className }: ISectionHeaderProps) => (
  <div className={`text-center ${className}`}>
    <h3 className="text-xs font-bold text-planasDark uppercase tracking-widest font-[Montserrat]">
      {subheading}
    </h3>
    <h1 className="text-4xl font-bold text-planasDark my-2 font-[Montserrat]">{heading}</h1>
  </div>
);
