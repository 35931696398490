import * as React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { StoreContext } from "context/StoreContext";
import { LogoDhl, StickerGuarantee } from "components/Graphics";
import { GatsbyImage, getImage, ImageDataLike, StaticImage } from "gatsby-plugin-image";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import SectionHeader from "components/SectionHeader";
import Ingredients from "components/Ingredients";

export const query = graphql`
  query ($id: String) {
    # Gatsby's File System Route API automatically adds props into page template components, like the id for the data layer node used to create the page and the field used to create the dynamic part of the route (the slug).
    productsYaml(id: { eq: $id }) {
      id
      name
      size
      slug
      rating
      reviews
      description

      included {
        name
        size
        isGift
      }

      available_for_sale
      price
      compare_price
      currency_code
      storefrontId
      variantId
      variantStorefrontId

      images {
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        alt
      }

      aboutImageAlt
      aboutImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

      benefitsImageAlt
      benefitsImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      benefits {
        icon
        title
      }

      ingredients {
        header {
          heading
          subheading
        }
        content {
          ingredient_list {
            action
            description
            name
          }
          image {
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            alt
          }
        }
      }

      routine {
        header {
          heading
          subheading
        }
        content {
          video {
            scriptCode
          }
          routine_list {
            title
            description
          }
        }
      }

      guarantee {
        title
        description
      }

      results
      # how_to_use
    }
  }
`;

import {
  ChevronRightIcon,
  ChevronLeftIcon,
  MinusIcon,
  ShieldCheckIcon,
  LockClosedIcon,
} from "@heroicons/react/solid";
const HeroImageGallery = ({ productImages }) => {
  const imgNumber = productImages.length;
  const [currentIdx, setCurrentIdx] = React.useState(0);
  return (
    <div className="grid sm:grid-cols-9 gap-3 mx-5 sm:mx-0">
      {/* SMALL IMAGES, style defined in data file */}
      <div className="col-span-1 hidden w-full h-full sm:block">
        <div className={`grid grid-rows-${imgNumber} gap-3 h-full`}>
          {productImages.map((image, idx) => (
            <div onClick={() => setCurrentIdx(idx)} className="relative">
              <div className="absolute w-full h-full hover:bg-white hover:opacity-30 z-10" />
              <GatsbyImage
                className="aspect-square"
                alt={image.alt}
                image={getImage(image.img)!}
                key={idx}
              />
            </div>
          ))}
        </div>
      </div>

      {/* BIG IMAGE, style defined in data file */}
      <div className="col-span-8 relative z-0">
        <div
          className={`${
            currentIdx == 7 && "hidden"
          } absolute w-14 bg-black bg-opacity-5 hover:bg-opacity-10 h-full flex flex-wrap content-center justify-center  z-10 right-0 sm:rounded-r-lg`}
          onClick={() => setCurrentIdx(currentIdx + 1)}
        >
          <ChevronRightIcon className="h-8" />
        </div>
        <div
          className={`${
            currentIdx == 0 && "hidden"
          } absolute w-14 bg-black bg-opacity-5 hover:bg-opacity-10 h-full flex flex-wrap content-center justify-center z-10 left-0 sm:rounded-l-lg`}
          onClick={() => setCurrentIdx(currentIdx - 1)}
        >
          <ChevronLeftIcon className="h-8" />
        </div>
        <div className="absolute top-5 right-5 z-10 drop-shadow-xl rotate-12">
          <StickerGuarantee />
        </div>
        {productImages.map((image, idx) => (
          <div className={`${currentIdx == idx ? "" : "hidden"}`}>
            <GatsbyImage
              className="aspect-square"
              alt={image.alt}
              image={getImage(image.img)!}
              key={idx}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const HeroReviewStars = ({ rating, reviews }) => (
  <div className="flex items-center">
    <ReviewStars stars={rating} size={5} />
    <p
      className="ml-2 text-xs italic text-planasDark"
      style={{ fontFamily: "Montserrat" }}
    >{`(${reviews} reseñas)`}</p>
  </div>
);
import ReviewStars from "components/ReviewStars";
import { ThreeDotsLoader } from "components/Graphics";
import { GiftIcon, PlusIcon } from "@heroicons/react/outline";
import { formatPrice } from "utils/formatPrice";
import Routine from "components/Routine";
import MoneyBackGuarantee from "components/MoneyBackGuarantee";

export default ({ data }) => {
  const { checkout, addVariantToCart, removeLineItem, buyNow, loading } =
    React.useContext(StoreContext);
  const [quantity, setQuantity] = React.useState(1);

  function getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const [activeVisitors, setActiveVisitors] = React.useState(getRndInteger(20, 98));

  const [itemsLeft, setItemsLeft] = React.useState(getRndInteger(9, 19));

  React.useEffect(() => {
    const interval = setInterval(() => {
      var change = getRndInteger(-2, 2);
      if (activeVisitors < 15) {
        change = Math.abs(change); // guard against very low numbers
      } else {
        setActiveVisitors(activeVisitors + change);
      }
    }, 7000);

    const interval2 = setInterval(() => {
      var change2 = getRndInteger(0, 1);
      if (itemsLeft == 5) {
        setItemsLeft((past) => past + getRndInteger(9, 19));
      } else {
        setItemsLeft((past) => past - change2);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  return (
    <>
      <div className="max-w-6xl mx-auto">
        <div className="grid sm:grid-cols-8 gap-8 mt-5 sm:mt-14">
          <div className="sm:col-span-5">
            <HeroImageGallery productImages={data.productsYaml.images} />
          </div>
          <div className="sm:col-span-3 mx-5 sm:mx-0 overflow-visible">
            <h1
              aria-label="product-name"
              className="uppercase text-[27px] leading-[34px] text-[#2c2c2c] font-[Montserrat] font-bold"
              children={
                data.productsYaml.variantId == "43442823102720"
                  ? data.productsYaml.name + ": Crema + Serum + Regalo"
                  : data.productsYaml.name
              }
            />

            {/* <h1
              className="uppercase text-2xl text-[#2c2c2c] font-[Montserrat] font-medium"
              children={data.productsYaml.name}
            /> */}
            <p className=" text-sm opacity-40 -mt-1 italic" children={data.productsYaml.size} />

            <div className="mt-1 mb-5">
              <HeroReviewStars
                rating={data.productsYaml.rating}
                reviews={data.productsYaml.reviews}
              />
              <div className="mt-3 w-full flex items-center">
                <span className="relative flex h-2.5 w-2.5 m-[5px]">
                  <span className="animate-[ping_2s_ease-in-out_infinite] absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500" />
                </span>
                <div className="text-sm ml-2">
                  {data.productsYaml.slug == "pack-antiaging-vip" && (
                    <>1 persona está mirando este producto actualmente</>
                  )}
                  {data.productsYaml.slug != "pack-antiaging-vip" && (
                    <>{activeVisitors} personas están mirando este producto actualmente</>
                  )}
                </div>
              </div>
            </div>

            <p
              aria-label="product-description"
              className="my-5 text-base font-normal"
              children={data.productsYaml.description}
            />

            {(data.productsYaml.variantId == "37601372012720" ||
              data.productsYaml.variantId == "37601372012720") && (
              <div className="my-5">
                <ul className="list-disc list-inside text-base text-[#143352] font-normal ml-3 space-y-2 font-['Source_Sans_Pro']">
                  {data.productsYaml.included.map(
                    (item: { name: string; size: string; isGift: boolean }) => (
                      <>
                        <li className="items-center">
                          {item.isGift && (
                            <div className="inline-block text-[#143352] mr-2 py-0.5 px-1.5 rounded bg-blue-300 bg-opacity-30 relative items-center border border-blue-200 text-[13px]">
                              <GiftIcon className="h-4 w-4 absolute mt-0.5" />
                              <p className="ml-5" children="Regalo" />
                            </div>
                          )}
                          {item.name}
                          <span className="ml-1.5 text-[#2e73b8] opacity-50">{item.size}</span>
                        </li>
                      </>
                    )
                  )}
                </ul>
              </div>
            )}
            <div className="my-5 overflow-visible">
              {data.productsYaml.available_for_sale ? (
                checkout.lineItems.some(
                  (item) => item.variant?.id === data.productsYaml.variantStorefrontId
                ) ? (
                  // IN BASKET
                  <>
                    <div className="flex items-center my-5">
                      <p className="w-full text-right tracking-wider text-2xl">
                        {formatPrice(
                          data.productsYaml.currency_code,
                          Number(data.productsYaml.price)
                        )}
                      </p>
                    </div>
                    <button
                      className="my-1 text-xl w-full py-3 font-medium font-[Montserrat] top-0  border-2 border-transparent border-red-400 hover:bg-red-400 transition-all"
                      children={
                        !loading ? (
                          "Eliminar de la cesta"
                        ) : (
                          <div className="relative text-transparent">
                            Añadir a la cesta {/* Here so that button doesn't shrink  */}
                            <ThreeDotsLoader className="w-10 h-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                          </div>
                        )
                      }
                      onClick={() => {
                        const item = checkout.lineItems.find(
                          (lineItem) => lineItem.variant.id == data.productsYaml.variantStorefrontId
                        );
                        removeLineItem(checkout.id, item.id);
                      }}
                    />
                  </>
                ) : (
                  // NOT IN BASKET
                  <>
                    <div className="md:flex items-center my-5">
                      <div className="flex items-center">
                        Cantidad:
                        <div className="flex items-center ml-4">
                          <button
                            className="bg-gray-100 rounded-full"
                            onClick={() => setQuantity(Math.max(1, quantity - 1))}
                          >
                            <MinusIcon className="w-4 h-4 m-2" />
                          </button>
                          <p className="mx-3">{quantity}</p>
                          <button
                            className="bg-gray-100 rounded-full"
                            onClick={() => setQuantity(quantity + 1)}
                          >
                            <PlusIcon className="w-4 h-4 m-2" />
                          </button>
                        </div>
                      </div>
                      {/* <p className="w-full text-right tracking-wider text-2xl">
                        {formatPrice(
                          data.productsYaml.currency_code,
                          Number(data.productsYaml.price)
                        )}
                      </p> */}
                      <p className="mt-5 md:mt-0 w-full tracking-wider text-2xl font-medium text-right">
                        {formatPrice(
                          data.productsYaml.currency_code,
                          Number(data.productsYaml.price)
                        )}{" "}
                        {data.productsYaml.compare_price && (
                          <span className="font-normal line-through">
                            {formatPrice(
                              data.productsYaml.currency_code,
                              Number(data.productsYaml.compare_price)
                            )}
                          </span>
                        )}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        addVariantToCart(data.productsYaml.variantStorefrontId, quantity);
                      }}
                      className="my-1 py-3 w-full text-xl text-[#143352]/80 font-medium font-[Montserrat] top-0 hover:brightness-[0.85] border-2 border-[#143352]/60 hover:shadow-xl transition-all"
                      children={
                        !loading ? (
                          "Añadir a la cesta"
                        ) : (
                          <div className="relative text-transparent">
                            Añadir a la cesta {/* Here so that button doesn't shrink  */}
                            <ThreeDotsLoader className="w-10 h-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                          </div>
                        )
                      }
                    />
                    <button
                      className="my-1 py-3 w-full text-xl text-white font-medium font-[Montserrat] top-0 bg-[#1d4aff] hover:brightness-[0.85] border-2 border-transparent hover:border-[#143352]/50 hover:shadow-xl transition-all"
                      children={
                        !loading ? (
                          "Comprar ya"
                        ) : (
                          <div className="relative text-transparent">
                            Añadir a la cesta {/* Here so that button doesn't shrink  */}
                            <ThreeDotsLoader className="w-10 h-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                          </div>
                        )
                      }
                      onClick={() => buyNow(data.productsYaml.variantStorefrontId, quantity)}
                    />
                  </>
                )
              ) : (
                <button
                  disabled
                  className="my-1 text-xl w-full py-3 font-medium font-[Montserrat] top-0 border-2 border-transparent border-[#EDDA8E]"
                  children="Agotado"
                />
              )}
            </div>

            {data.productsYaml.slug != "pack-antiaging-vip" && (
              <p className={`text-red-600 brightness-[1.3] font-bold text-center -mt-3`}>
                {itemsLeft == 1
                  ? `¡Solamente queda 1 unidad!`
                  : `¡Solo quedan las ultimas ${17} unidades!`}
                {/* `¡Solo quedan ${PlacesLeft()} unidades!`} `¡Solo quedan ${itemsLeft} unidades!`}*/}
              </p>
            )}

            <div className="flex space-x-2 text-[0.65rem] text-black/50 text-center tracking-wide mt-6">
              <div className="w-1/3 bg-gray-100 border py-1.5 px-1 space-y-1 flex flex-col items-center justify-center">
                <LockClosedIcon className="w-full h-3.5" />
                <p children="Pago Seguro" />
              </div>

              <div className="w-1/3 bg-gray-100 border py-1.5 px-1 space-y-1 flex flex-col items-center justify-center">
                <LogoDhl className="w-full h-3.5" />
                <p children="Envio VIP Gratis" />
              </div>

              <div className="w-1/3 bg-gray-100 border py-1.5 px-1 space-y-1 flex flex-col items-center justify-center">
                <ShieldCheckIcon className="w-full h-3.5" />
                <p className="block sm:hidden" children="Devolución" />
                <p className="hidden sm:block" children="Devolución de Dinero" />
              </div>
            </div>
          </div>
        </div>

        {/* Further details */}
        <div className="grid sm:grid-cols-10 gap-8 my-20 mx-5 sm:mx-0">
          <div className="sm:col-span-6">
            <h1 className="text-2xl font-bold text-planasDark font-[Montserrat]">
              La Solución Más Eficaz para el Envejecimiento
            </h1>
            <div className="text-base font-normal text-planasDark my-5 space-y-3 opacity-80 font-['Source_Sans_Pro'] text-[17px]">
              <p>
                Nuestra linea cosmética está clínicamente probado para ayudar a reducir los signos
                de envejecimiento, eliminando arrugas, surcos, y reponiendo el volumen natural de la
                piel.
              </p>
              <p>Nuestros clientes notan cambios visibles en dos días.</p>
              <p>
                Esto es posible gracias a uno de nuestros ingredientes claves, el Calendula
                Marigold, un activo único, con estudios clínicos, y patentado por el Doctor Jorge
                Planas que dedicó más de 10 años a investigarlo.
              </p>
              <p>
                El Calendula Marigold se compone de extracto de Caléndula fotoestimulada,
                multiplicando por 6 los beneficios naturales de la planta. Como son su acción
                antiinflamatoria, cicatrizante, antimicrobiana y antiséptica.
              </p>
              <p>
                El Doctor Jorge Planas lleva usando este exclusivo extracto en sus pacientes con
                resultados espectaculares, incluso en pieles con alteraciones severas como psoriasis
                e irritaciones.
              </p>
            </div>
          </div>
          <div className="sm:ml-5 sm:col-span-4 ">
            <GatsbyImage
              className="aspect-square"
              alt={data.productsYaml.aboutImageAlt}
              image={getImage(data.productsYaml.aboutImage)!}
            />
          </div>
        </div>

        {/* Benefits */}
        <div className="grid sm:grid-cols-10 gap-8 my-20 mx-5 sm:mx-0">
          <div className="sm:mr-5 sm:col-span-4 ">
            <GatsbyImage
              className="aspect-square"
              alt={data.productsYaml.aboutImageAlt}
              image={getImage(data.productsYaml.benefitsImage)!}
            />
          </div>
          <div className="sm:col-span-6">
            <h1 className="text-2xl font-bold text-planasDark font-[Montserrat]">
              Propiedades y Beneficios
            </h1>
            <ul className="text-base font-normal my-5 opacity-80 divide-y divide-gray-300  font-['Source_Sans_Pro'] text-[17px]">
              {data.productsYaml.benefits.map((benefit, idx) => (
                <li key={idx} className="py-1.5 items-center align-middle">
                  <div className="ml-1 flex items-center">
                    {benefit.icon == "check" ? (
                      <CheckIcon className="h-4 w-4" />
                    ) : (
                      <XIcon className="h-4 w-4" />
                    )}
                    <span className="ml-2 font-['Source_Sans_Pro]">{benefit.title}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Planas Guarantee */}
      <section id="GUARANTEE" className="bg-[#143352] py-10">
        <div className="max-w-6xl mx-auto">
          <MoneyBackGuarantee {...data.productsYaml.guarantee} />
        </div>
      </section>

      <div className="max-w-6xl mx-auto">
        {/* Ingredients */}
        <div className="gap-8 my-20">
          <SectionHeader {...data.productsYaml.ingredients.header} />
          <Ingredients {...data.productsYaml.ingredients.content} />
        </div>

        {/* Routine */}
        <Routine {...data.productsYaml.routine} />
      </div>

      {/* REVIEWS -------------------------------------------------------------------------------*/}
      <div className="bg-gray-100">
        <div className="max-w-6xl mx-auto py-24 ">
          <Reviews />
        </div>
      </div>
      <div className="bg-gray-50 pt-24 pb-24">
        <FAQs />
      </div>
      <div className="max-w-6xl mx-auto py-14 ">
        <OtherProducts />
      </div>

      {/* Incentives */}
      <div className="bg-blue-50">
        <div className="max-w-6xl mx-auto py-7 ">
          <Incentives />
        </div>
      </div>
    </>
  );
};

import Reviews from "components/MiniVSL/Reviews";
import FAQs from "components/MiniVSL/FAQ";
import OtherProducts from "components/MiniVSL/OtherProducts";
import Incentives from "components/MiniVSL/Incentives";

import PlacesLeft from "../../utils/placesLeft";
